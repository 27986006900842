import * as React from "react"
import Layout from "../components/layout";
import * as Karte from "../components/karte"
import Infobox from "../components/karte/container/infobox";

const Page = (props) => {

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            if (!!window.msCrypto) {
                document.getElementById('main').innerHTML = '<div class="container noIE">' +
                    '<div class="row"><div class="col-12"><p>Die Karte steht im Internet Explorer nicht zur Verfügung.<br/> Bitte nutzen Sie einen anderen Browser um die Karte aufzurufen</p></div></div></div>';
                document.querySelector('.loading-karte').classList.remove('show');
            } else {
                Karte.default();
            }
        }
    }, []);

    const zoomIn = () => {
        document.querySelectorAll('.ol-zoom-in').forEach((el) => {
            el.click();
        });
    }

    const zoomOut = () => {
        document.querySelectorAll('.ol-zoom-out').forEach((el) => {
            el.click();
        });
    }

    const pageTitle = "Orte der Demokratie - Karte";
    return (
        <Layout pageTitle={pageTitle} props={props} className="karte">
            <div className="loading-karte show">
                <img src={"/images/Karte/logo_weiß.svg"} className="logo" alt=""/>
            </div>
            <main id="main">
                <div id="popup" className="ol-popup" style={{display: "none"}}/>

                <div id="map">
                    <div id="map-overlay"/>
                </div>

                <Infobox/>

                <div className="zoom">
                    <button id="zoom-in" className="icon-plus" onClick={zoomIn}>+</button>
                    <button id="zoom-out" className="icon-minus" onClick={zoomOut}>-</button>
                </div>

                {/*
                <Container.If env="w">
                    <div className="information" id="modal">
                        <Link to="#" rel="noreferrer">Informationen</Link>
                    </div>
                    <div className="modal info">
                        <div className="modal-body">
                            <button className="icon-close">&times;</button>
                            <p>Herausgeber: &nbsp;
                                <Link to="http://www.bayern.landtag.de/" rel="noreferrer" target="_blank">
                                    Bayerischer Landtag | Landtagsamt; Stabstelle Öffentlichkeitsarbeit
                                </Link>
                            </p>
                            <p>Geodaten: &nbsp;
                                <Link to="http://www.ldbv.bayern.de/" rel="noreferrer" target="_blank">
                                    Landesamt für Digitalisierung, Breitband und Vermessung</Link>
                            </p>
                            <p>Realisation: &nbsp;
                                <Link to="https://www.simplethings.de/design/webdesign/" rel="noreferrer"
                                      target="_blank">
                                    SimpleThings GmbH | Webdesign, Softwareentwicklung</Link>
                            </p>
                            <p>Impressum: &nbsp;
                                <Link to="https://www.bayern.landtag.de/service/impressum/" rel="noreferrer"
                                      target="_blank">Impressum</Link>
                            </p>
                        </div>
                    </div>
                </Container.If>
                */}
            </main>
        </Layout>
    )
}

export default Page
