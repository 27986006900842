import Overlay from 'ol/Overlay';

function PopUp(options) {
    this.options = options;

    this.featureId = null;
    this.overlay = new Overlay({
        element: options.target,
        autoPan: false
    });

    this.timeout = null;

    this.offset = options.offset;
    this.margin = options.margin;

    this.map = options.map;
    this.map.map.addOverlay(this.overlay);
}

PopUp.prototype.on = function (event, callback) {
    this.options.target.addEventListener(event, callback);
};

PopUp.prototype.is = function (event) {
    document.querySelectorAll(event).forEach((el) => {
        if (this.options.target === el) {
            return true;
        }
    });
    return false;
};

PopUp.prototype.open = function (feature, content = null, callback = null) {
    if (this.featureId === feature.getId()) {
        return;
    }
    this.featureId = feature.getId();
    this.options.target.innerHTML = content;
    this.options.target.style.display = 'block';

    this.overlay.setPosition(feature.getGeometry().getCoordinates());

    if (callback) {
        callback();
    }

    this.updateOffset();
};

PopUp.prototype.close = function () {
    this.featureId = null;
    this.overlay.setPosition(undefined);
};

PopUp.prototype.updateOffset = function () {
    if (this.featureId) {
        let scale = this.map.scale(this.map.view.getResolution());

        let position = this.map.map.getPixelFromCoordinate(this.overlay.getPosition());

        const targetBox = this.options.target.getBoundingClientRect();
        const mapBox = this.map.options.target.getBoundingClientRect();

        let overlay = [targetBox.width, targetBox.height];
        let map = [mapBox.width, mapBox.height];

        let left = (this.offset.x - this.margin.left) * scale - overlay[0];
        let right = (this.offset.x + this.margin.right) * scale;

        let top = (this.offset.y - this.margin.top) * scale - overlay[1];
        let bottom = (this.offset.y + this.margin.bottom) * scale;

        let x = (position[0] > map[0] / 2) ? left : right;
        let y = (position[1] + overlay[1] > map[1] && position[1] + overlay[1] + top > 30) ? top : bottom;

        this.overlay.setOffset([x, y]);
    }
};

PopUp.prototype.timeoutAfter = function (milliseconds) {
    if (this.timeout !== null) {
        clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
        this.close();
    }, milliseconds);
};

PopUp.prototype.clearTimeout = function () {
    if (this.timeout !== null) {
        clearTimeout(this.timeout);
        this.timeout = null;
    }
};

export default PopUp;
