import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Gaibach/teaser-gaibach.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1828 / 1832: Gaibach </h2>
                    </div>
                </div>
                <p><i>„…einem Staate anzugehören, dessen Verfassung fest gegen äußere und innere Angriffe
                    steht…“</i><br/><br/></p>
                <p>Aus Begeisterung für die bayerische Verfassung von 1818 ließ Franz Erwein Graf von Schönborn bei
                    seinem Schloss in Gaibach eine monumentale Säule errichten. 1828 wurde die von dem Architekten Leo
                    von Klenze entworfene Säule fertiggestellt. Die Einweihungsfeier fand in Anwesenheit von König
                    Ludwig I. statt. Auch in den folgenden Jahren richtete Schönborn in Gaibach Verfassungsfeiern aus.
                </p>
                <p>
                    Mit den vom König erlassenen Verfassungen von 1808 und 1818 wandelte Bayern sich zur
                    konstitutionellen Monarchie. Die Verfassung war für ihre Zeit fortschrittlich. Sie enthielt
                    Grundrechte und wurde zum Ausgangspunkt für die Entwicklung des modernen Parlamentarismus in Bayern.
                    In der Bevölkerung wuchs der Wunsch nach mehr Mitbestimmungs- und Freiheitsrechten. Nach der
                    Julirevolution 1830 in Frankreich jedoch stieg beim König das Misstrauen gegen eine erweiterte
                    politische Beteiligung. 1832 wurden Forderungen nach liberalen und demokratischen Umgestaltungen zum
                    Thema verschiedener Verfassungsfeiern, in Gaibach fand am 27. Mai wieder eine Feier mit mehreren
                    Tausend Menschen statt.
                </p>
                <p>
                    Beim Gaibacher Fest 1832 war der Würzburger Bürgermeister Wilhelm Joseph Behr eine zentrale Figur.
                    Er hielt eine Rede zum Wunsch nach einer Verfassungsreform im liberalen Sinne. Von ihm ging auch die
                    Initiative aus, sich entsprechend an den König zu wenden. Nach dem Fest wurde Behr wegen Hochverrats
                    zu Festungshaft verurteilt.
                </p>
                <p>
                    Das Gaibacher Fest entwickelte sich von einer Feier für die von oben verordnete Verfassung von 1818
                    zu einem Symbol für das Ringen um erweiterte politische Teilhabe. Die Bayerische Einigung e.V. &
                    Bayerische Volksstiftung erinnert in Gaibach regelmäßig an die Verfassungstraditionen Bayerns.
                </p>
            </div>
            <Link to="/orte/gaibach/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

