import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Herrenchiemsee/teaser-herrenchiemsee.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1948: Herrenchiemsee </h2>
                    </div>
                </div>
                <p><i>„Das Werk… wird so vielen großen Aufgaben gerecht werden müssen.“</i><br/><br/></p>
                <p>Auf Herrenchiemsee tagten im August 1948 Vertreter der Länder der westlichen Zonen mit dem Auftrag,
                    einen Verfassungsentwurf auszuarbeiten.
                </p>
                <p>
                    Als 1948 die gemeinsame Verwaltung Deutschlands durch die Siegermächte zerbrach, wurde die Gründung
                    eines Weststaats in die Wege geleitet. Im Juli 1948 erhielten die Ministerpräsidenten der Westzonen
                    den Auftrag, eine verfassunggebende Versammlung einzuberufen. Die Ministerpräsidenten setzten einen
                    Ausschuss für die Vorbereitung einer Verfassung ein. Auf Einladung des Bayerischen
                    Ministerpräsidenten Hans Ehard trafen sich die Vertreter der Länder auf Herrenchiemsee. Bayern
                    brachte den „Entwurf eines Grundgesetzes“ in die Beratungen ein.
                </p>
                <p>
                    Auf Herrenchiemsee kamen über 30 Fachleute zusammen. Der Verfassungskonvent führte die Beratungen im
                    Plenum und in drei Unterausschüsse durch, die Grundsatz-, Zuständigkeits- und Organisationsfragen
                    diskutierten. Nach der NS-Barbarei rückte die Unantastbarkeit der Würde des Menschen ins Zentrum.
                    Nach 14 Sitzungstagen lag ein Bericht vor, in dem Probleme und unterschiedliche Standpunkte
                    zusammengefasst waren, vor allem aber ein 149 Artikel umfassender kommentierter Entwurf für ein
                    Grundgesetz. Diese Vorarbeiten bildeten für den Parlamentarischen Rat in Bonn eine wichtige
                    Grundlage für die Beratung des Grundgesetzes. Der Entwurf von Herrenchiemsee beeinflusste dessen
                    Arbeit stark. Am 23. Mai 1949 trat das Grundgesetz in Kraft. Bis heute bestimmt es den Charakter der
                    Bundesrepublik als demokratischer, freiheitlicher und föderal organisierter Rechtsstaat mit starken
                    Ländern.
                </p>
            </div>
            <Link to="/orte/herrenchiemsee/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

