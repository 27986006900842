import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Bamberg/teaser-bamberg.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1919: Bamberg – Spiegelsaal der Harmonie </h2>
                    </div>
                </div>
                <p><i>„Die Staatsgewalt geht von der Gesamtheit des Volkes aus.“</i><br/><br/></p>
                <p>Im Spiegelsaal der Bamberger Harmonie fanden 1919 die Beratungen des Landtags und die Abstimmung über
                    die erste demokratische Verfassung Bayerns statt.
                </p>
                <p>
                    In München hatte sich nach der Revolution die Lage zunächst stabilisiert. Aus ersten allgemeinen
                    Wahlen im Januar 1919 ging ein neuer bayerischer Landtag mit klaren Mehrheiten für die Parteien der
                    Mitte hervor: Bayerische Volkspartei, Mehrheitssozialdemokraten, Deutsche Demokratische Partei. Sie
                    strebten eine parlamentarische Demokratie an. Als der Landtag am 21. Februar zusammentreten sollte,
                    ermordete der junge Offizier Anton Graf von Arco auf Valley den Vorsitzenden der
                    Revolutionsregierung, den Unabhängigen Sozialdemokraten Kurt Eisner. Im Anschluss kam es zu
                    Schießereien im Landtag mit Toten und dem schwer verletzten Vorsitzenden der
                    Mehrheitssozialdemokraten Erhard Auer, der sich schon 1917 mit einem umfassenden Antrag im Landtag
                    für die Demokratisierung eingesetzt hatte. Erst im März 1919 konnte der Landtag wieder
                    zusammentreten und den Sozialdemokraten Johannes Hoffmann zum Vorsitzenden des Ministerrats wählen.
                    Die folgende Ausrufung von revolutionären Räterepubliken führte zur Destabilisierung der
                    Verhältnisse in München. Regierung und Landtag wichen nach Bamberg aus.
                </p>
                <p>
                    Die bereits von der Revolutionsregierung Eisner eingeleitete Arbeit an einer neuen Verfassung wurde
                    in Bamberg wieder aufgegriffen und in einem Übereinkommen der Parteien entscheidend vorangebracht.
                    In Bamberg verabschiedete das Plenum des Landtags im August mit großer Mehrheit die erste
                    demokratische Verfassung Bayerns. Sie trat am 15. September 1919 in Kraft.
                </p>
                <p>
                    Die „Bamberger Verfassung“ setzte Prinzipien einer parlamentarisch-repräsentativen Demokratie um.
                    Erstmals waren in der bayerischen Verfassung die Volkssouveränität, das allgemeine Wahlrecht und
                    aufseiten der Legislative neben dem Landtag auch plebiszitäre Instrumente wie Volksbegehren und
                    Volksentscheid sowie aufseiten der Exekutive das Amt des bayerischen Ministerpräsidenten verankert.
                </p>
            </div>
            <Link to="/orte/bamberg/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>
        </div>
    )
}

export default Overlay

