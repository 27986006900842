import {isTerminal} from "../container/container";

export function onClick(selector, callback) {

    if (typeof selector === 'object') {
        if (selector[0] !== undefined) {
            selector[0].addEventListener('click', callback);
        } else {
            selector.addEventListener('click', callback);
        }
    } else {
        document.querySelectorAll(selector).forEach((el) => {
            el.addEventListener('click', callback);
        })
    }
}

export function copyToClipboard(value) {
    let textArea = document.createElement("textarea");

    textArea.value = value;
    textArea.style.position = "fixed";  //avoid scrolling to bottom

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
        let msg = document.execCommand('copy') ? 'successful' : 'unsuccessful';

        console.log('Copying was ' + msg);
    } catch (err) {
        console.error('Unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function normalize(index, max) {
    return ((index % max) + max) % max;
}

export function createSVGElement(type, attributes) {
    let element = document.createElementNS("http://www.w3.org/2000/svg", type);

    Object.keys(attributes).forEach(function (key) {
        element.setAttribute(key, attributes[key]);
    });

    return element;
}

/**
 *
 * @returns {boolean}
 */
export function isMobileOrTerminal() {
    if (typeof window !== `undefined`) {
        return window.innerWidth < 992 || isTerminal();
    }
}

/**
 *
 * @param html
 * @returns {string}
 */
export function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function showElements(selector) {
    if (typeof selector === 'object') {
        selector.classList.remove('hide');
        selector.classList.add('show');
        return;
    }

    document.querySelectorAll(selector).forEach((el) => {
        el.classList.remove('hide');
        el.classList.add('show');
    });
}

export function hideElements(selector, duration) {

    if (typeof selector === 'object') {
        selector.classList.remove('show');
        if (duration > 0) {
            setTimeout(() => {
                selector.classList.add('hide');
            }, duration);
        }
        return;
    }

    document.querySelectorAll(selector).forEach((el) => {
        el.classList.remove('show');
        if (duration > 0) {
            setTimeout(() => {
                el.classList.add('hide');
            }, duration);
        }
    });
}
