import Map from './Map';
import PopUp from './PopUp';
import Infobox from './Infobox';
import {isMobileOrTerminal, onClick} from './Helper';
import {RenderOrt} from "../container/render";

import {overlays} from "./orte";
import {isTerminal} from "../container/container";

const init = () => {
    const SHOW_POPUPS = false;
    const apikey = 'e11dae0a-4131-4891-ad39-36e743de89d3';
    let bayernWMTS;
    if (isTerminal()) {
        bayernWMTS = '/map/WMTSCapabilities.xml';
    } else {
        bayernWMTS = 'https://geoservices.bayern.de/wmts/v1/' + apikey + '/1.0.0/WMTSCapabilities.xml';
    }

    const bayernKML = '/map/wahlkreise_bayern.kml.gz';

    const map = new Map({
        target: document.getElementById('map'),
        projection: 'EPSG:3857',
        extent: [500000, 5500000, 2100000, 7020000],
        zoom: 8,
        minZoom: 6,
        maxZoom: isTerminal() ? 10 : 18
    });

    const popup = new PopUp({
        target: document.getElementById('popup'),
        offset: {
            x: 0,
            y: 0
        },
        margin: {
            left: 0,
            right: 0,
            top: 90,
            bottom: 10
        },
        map: map,
    });

    const infobox = new Infobox({
        container: 'infobox',
        wheel: 'infobox-wheel',
        content: 'infobox-content',
        closeButton: 'infobox-close',
        mapOverlay: 'map-overlay',
        map: map,
        menu: null
    });

    if (!isTerminal()) {
        map.loadCapabilities(bayernWMTS).then(function () {
            return map.loadWahlkreise(bayernKML);
        }).then(function () {
            return map.loadAbgeordneteLocal();
        });
    } else {
        map.loadWahlkreise(bayernKML);
        map.loadAbgeordneteLocal();
    }

    onClick('#map-overlay', function () {
        infobox.close()
    });

    onClick('#modal', function () {
        document.querySelectorAll('.modal.info').forEach((el) => {
            el.classList.add('open');
        });
    });

    onClick('.modal .icon-close', function () {
        document.querySelectorAll('.modal').forEach((el) => {
            el.classList.remove('open');
        });
        document.querySelectorAll('.iframecontainer').forEach((el) => {
            el.innerHTML = '';
        });
    });

    if (typeof window !== `undefined`) {
        window.addEventListener('resize', function () {
            infobox.resize();
        });
    }

    map.on('singleclick', function (event) {
        event.preventDefault();

        let features = map.getFeaturesAtPixel(event.pixel);

        if (features.rhombus.length > 0) {
            let id = features.rhombus[0].getId();

            if (map.abgeordnete[id].fraktion.id === '0') {
                infobox.state('abgeordneter', id, map.getAbgeordnetenKeys());
            }
        } else {
            infobox.close();
            popup.close();
        }
    });

    map.on('pointermove', function (event) {

        let features = map.getFeaturesAtPixel(event.map.getEventPixel(event.originalEvent));

        if (features.rhombus.length > 0 && !isMobileOrTerminal()) {

            const id = features.rhombus[0].getId();
            const abgeordneter = map.abgeordnete[id];

            if (abgeordneter.fraktion.id === '0') {
                if (popup.featureId === null || popup.timeout !== null) {
                    if (SHOW_POPUPS) {
                        popup.open(
                            features.rhombus[0],
                            RenderOrt(overlays[id]),
                            null);
                    }
                    this.getTargetElement().style.cursor = 'pointer';
                }
            } else {
                this.getTargetElement().style.cursor = '';
            }
        } else {
            this.getTargetElement().style.cursor = '';
        }
    });

    if (SHOW_POPUPS) {
        map.on('movestart', function () {
            popup.close();
        });

        popup.on('mouseover', () => {
            popup.clearTimeout();
        });

        popup.on('mouseleave', function () {
            popup.timeoutAfter(500);
        });
    }
}

export default init;
