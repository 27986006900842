import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>
            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Muenchen-Maximilianeum/teaser-maximilianeum.jpg"}/>
                    <figcaption>
                        Quelle: Bildarchiv Bayerischer Landtag
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    München – Maximilianeum </p>
                <p className="ort-subheader">
                    <i>„Dem Landtag ist im Maximilianeum ein würdiges Heim bereitet worden.“</i>
                </p>
                <Link to="/orte/muenchen-maximilianeum/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>
        </div>
    )
}

export default Overlay

