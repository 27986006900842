import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>

            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Wohlmuthshuell/teaser-wohlmuthshuell.jpg"}/>
                    <figcaption>
                        Quelle: Foto: Touristinformation Ebermannstadt
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    1945: Wohlmuthshüll </p>
                <p className="ort-subheader">
                    <i>„… Buergermeisters were installed in office …“</i>
                </p>
                <Link to="/orte/wohlmuthshuell/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>

        </div>
    )
}

export default Overlay

