import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Wohlmuthshuell/teaser-wohlmuthshuell.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1945: Wohlmuthshüll </h2>
                    </div>
                </div>
                <p><i>„… Buergermeisters were installed in office …“</i><br/><br/></p>
                <p>Bürger der Gemeinde Wohlmuthshüll in Oberfranken wählten am 18. Juli 1945 bei einer abendlichen
                    Versammlung die Kandidaten für das Bürgermeisteramt und den Gemeinderat. Wenige Wochen nach
                    Kriegsende organisierte der von den amerikanischen Besatzungskräften bestellte kommissarische
                    Landrat Georg Mandt eine Wahl, während andernorts neue Bürgermeister in der Regel von US-Offizieren
                    nach Erkundigungen bei Geistlichen oder NS-Gegnern eingesetzt wurden. Die Mehrheit der Stimmen fiel
                    auf Johann Sponsel, der dann von der US-Militärregierung tatsächlich als Bürgermeister bestätigt
                    wurde. Zur Versammlung im Gasthaus Brütting waren – wie in ländlichen Gemeinden oft noch Praxis –
                    nur die Männer des Ortes im Alter von über 21 Jahren zusammengekommen. Im Beisein des Landrats
                    schrieb jeder die Namen seiner Kandidaten auf einen Zettel.
                </p>
                <p>
                    Die Vorgänge in Wohlmuthshüll wurden Anfang August 1945 in einem Zeitungsbericht im „Bayerischen
                    Tag“ als „die ersten freien Wahlen in Deutschland“ bezeichnet. Diese zu ermöglichen, sei eine
                    Anerkennung für Wohlmuthshüll gewesen, da die Menschen des Ortes überwiegend eine negative
                    Einstellung zum Nationalsozialismus hatten. Andere Dokumente geben darüber allerdings keine
                    Auskunft.
                </p>
                <p>
                    Von der Berichterstattung im „Bayerischen Tag“ fand die Wahl in Wohlmuthshüll zunächst Eingang in
                    geschichtswissenschaftliche Darstellungen und später, begleitet von kontroverser Diskussion, in das
                    kulturelle Gedächtnis. US-Generalkonsul Matthew Rooney und der bayerische Innenminister Günther
                    Beckstein erinnerten am 18. Juli 2005 in einem Festakt an die frühe Wahl der Gemeindevertreter in
                    Wohlmuthshüll und bezeichneten ihn mit Blick auf die Rolle der USA in der unmittelbaren
                    Nachkriegszeit als einen Beitrag zur Erneuerung von Demokratie und Gesellschaft in Bayern.
                </p>
            </div>
            <Link to="/orte/wohlmuthshuell/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

