import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Nuernberg/teaser-nuernberg.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1919: Nürnberg – Saalbau des Industrie- und Kulturvereins </h2>
                    </div>
                </div>
                <p><i>„Die Macht der Arbeiter liegt in ihrer Organisation.“</i><br/><br/></p>
                <p>Im Juli 1919 wurde in Nürnberg der Allgemeine Deutsche Gewerkschaftsbund (ADGB) gegründet. Von diesem
                    Dachverband erhofften die freien Gewerkschaften sich stärkeren Einfluss zur Vertretung der
                    Interessen der Arbeitnehmerinnen und Arbeitnehmer. Ab 1890 waren die Einzelverbände der freien
                    Gewerkschaften in der Generalkommission der Gewerkschaften Deutschlands zusammengeschlossen. In der
                    Weimarer Republik wuchs die Rolle der Gewerkschaften als gesellschaftliche Kraft bei der
                    Ausgestaltung des Sozialstaats und der Festigung der Demokratie.
                </p>
                <p>
                    Richtungsweisend war der Kongress in Nürnberg 1919, wo man sich im Gebäude des Industrie- und
                    Kulturvereins eingemietet hatte. Anwesend waren über 600 Delegierte von 52 Gewerkschaften. Sie
                    vertraten die Interessen von 4,8 Millionen Mitgliedern. Alle beim Kongress vertretenen Verbände
                    schlossen sich dem ADGB an, darunter die Verbände der Metall-, Land-, Fabrik- und Textilarbeiter und
                    arbeiterinnen. Zu den Zielen gehörten Tarifverträge über Löhne und Arbeitsbedingungen und die
                    verbindliche Einführung des Achtstundentags.
                </p>
                <p>
                    1920 waren über acht Millionen Menschen im ADGB organisiert. In der Weimarer Republik setzten sich
                    die freien Gewerkschaften nicht nur für die Belange der Arbeiterschaft ein, sondern auch für den
                    Schutz der parlamentarischen Demokratie, etwa als sie sich gegen den Kapp-Lüttwitz-Putsch stellten.
                    Obwohl Teile der ADGB-Führung 1933 auf eine „Anpassungspolitik“ setzten, ging das NS-Regime rigoros
                    gegen die Gewerkschaften vor. 1933 wurden die Gewerkschaften zerschlagen und gleichgeschaltet.
                    Zahlreiche Gewerkschaftsmitglieder waren im Widerstand aktiv. In der Nachkriegszeit organisierten
                    die Gewerkschaften sich bald neu und sind auch heute wichtige Akteure der demokratischen
                    Mitbestimmung.
                </p>
            </div>
            <Link to="/orte/nuernberg/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

