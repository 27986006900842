import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (

        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Memmingen/teaser-memmingen.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1525: Memmingen – Haus der Kramerzunft </h2>
                    </div>
                </div>
                <p><i>„…das wir frey seien und wollen sein.“</i><br/><br/></p>
                <p>Im Haus der Kramerzunft in Memmingen wurde vor rund 500 Jahren Freiheits- und Verfassungsgeschichte
                    geschrieben. Während des Bauernkriegs (1524–1526) kämpften die Bauern gegen Missstände der
                    bestehenden Herrschaftsform und für mehr Rechte. Rund 50 Vertreter der Bauern aus Oberschwaben kamen
                    1525 in Memmingen zusammen.
                </p>
                <p>
                    Die Bauern verabschiedeten schriftlich ihre Forderungen – die Zwölf Artikel. Unterstützt wurden sie
                    auch von Bürgern und Anhängern der Reformation. Die Zwölf Artikel begründen soziale und politische
                    Ziele mit dem Evangelium. Es ging unter anderem um freie Pfarrerwahl, Aufhebung der Leibeigenschaft,
                    Erleichterungen bei den Abgaben und eine Erweiterung der Gemeinderechte.
                </p>
                <p>
                    Die Flugschrift mit den Zwölf Artikeln verbreitete sich rasch: In nur zwei Monaten erschienen davon
                    im ganzen Reich 28 Drucke, über 25.000 Exemplare waren im Umlauf. Im Aufstandsgebiet des
                    Bauernkriegs – weit über Oberschwaben hinaus – hatten sie Bedeutung für den Kampf der Bauern.
                </p>
                <p>
                    Bei den Treffen in Memmingen diskutierten die Bauern auch ihr Vorgehen: Sollte man verhandeln oder
                    kämpfen? Sie gründeten eine Vereinigung und schrieben Grundsätze in einer „Bundesordnung“ fest. Nun
                    sollten Verhandlungen mit den Herrschaften geführt werden.
                </p>
                <p>
                    Die Obrigkeiten gingen nicht auf das Verhandlungsangebot ein, vielmehr schlug der Schwäbische Bund
                    die Bauern militärisch nieder. Dennoch konnten die Bauern zum Teil ihre Rechtsstellung verbessern.
                    Die Zwölf Artikel gehören zu den frühen niedergeschriebenen Forderungen nach Freiheits- und
                    Mitbestimmungsrechten in Europa.
                </p>
            </div>
            <Link to="/orte/memmingen/" className='ort-text icon-arrow-right '> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

