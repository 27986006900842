import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>
            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Muenchen-LMU/teaser-lmu.jpg"}/>
                    <figcaption>
                        Quelle: Bildarchiv Bayerischer Landtag
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    1946: München – Große Aula der Ludwig-Maximilians-Universität </p>
                <p className="ort-subheader">
                    <i>„…die Segnungen des Friedens, der Menschlichkeit und des Rechtes dauernd zu sichern…“ </i>
                </p>
                <Link to="/orte/muenchen-lmu/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>
        </div>
    )
}

export default Overlay

