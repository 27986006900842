import {createSVGElement, hideElements, isMobileOrTerminal, normalize, onClick, showElements} from './Helper';
import {gsap, TweenLite} from 'gsap';
import Draggable from 'gsap/Draggable.js';

import {infoboxes} from "./orte";
import {RenderOrt} from "../container/render";
import {isTerminal, isLandscape} from "../container/container";

if (typeof window !== `undefined`) {
    gsap.registerPlugin(Draggable);
}

function Infobox(options) {
    this.map = options.map;
    this.menu = options.menu;

    this.events = {};

    this.container = document.getElementById(options.container);
    this.content = document.getElementById(options.content);
    this.wheel = document.getElementById(options.wheel);

    this.closeButton = document.getElementById(options.closeButton);
    this.mapOverlay = document.getElementById(options.mapOverlay);

    this.current = null;
    this.target = null;

    this.isCards = true;

    this.wheelTabs = [];
    this.draggable = undefined;

    this.init(100, 17);

    this.resize();
}

Infobox.prototype.init = function (r, n) {
    let self = this;

    let step = 360 / n;
    let size = r + 10;

    let content = createSVGElement('svg', {
        'id': 'wheel-content',
        'viewBox': (-size) + ' ' + (-size) + ' ' + (2 * size) + ' ' + (2 * size)
    });

    content.appendChild(createSVGElement('circle', {
        'class': 'wheel-trigger',
        'r': 100,
        'fill': 'white'
    }));

    for (let i = 0; i < n; i++) {
        let tab = createSVGElement('g', {
            'id': 'tab_' + i,
            'class': 'tab wheel-trigger',
            'transform': 'rotate(' + (step * i - 90) + '), translate(' + r + ', 0), rotate(90)'
        });

        content.appendChild(tab);
        self.wheelTabs.push(tab);
    }

    self.wheel.appendChild(content);

    self.draggable = Draggable.create(self.wheel, {
        type: "rotation",
        trigger: '.wheel-trigger',
        svgOrigin: size + ' ' + size,
        minimumMovement: 0,
        onRelease: function () {
            if (self.current) {
                let currentIndex = (self.current.index === false) ? 0 : self.current.index;

                self.rotate(-(Math.round((this.rotation / step + currentIndex) * 1.5)));
            }

            TweenLite.to(self.content, 0.5, {
                ease: "power2.out",
                y: 0
            });
        },
        onDrag: function () {
            if (self.current) {
                let currentIndex = (self.current.index === false) ? 0 : self.current.index;

                TweenLite.to(self.content, 0, {
                    y: Math.pow(Math.abs(this.rotation / step + currentIndex) * 3, 4) * 1000
                });
            }
        }
    })[0];

    onClick(self.closeButton, function () {
        self.close();
    });
};

Infobox.prototype.registerEvents = function (events) {
    this.events = events;

    Object.keys(events).forEach(function (key) {
        onClick(key, events[key]);
    });
};

/**
 *
 * @param type
 * @param id
 * @param filter
 */
Infobox.prototype.state = function (type, id, filter) {
    if (id === undefined) {
        return;
    }

    let state = new State(this.current);

    state.type = type;
    state.id = id;

    state.filter = filter.map(f => f.toString());
    state.index = state.filter.indexOf(id.toString());

    if (state.index === -1) {
        state.index = false;
    }

    this.upateState(state);
};

Infobox.prototype.close = function () {
    this.current = null;
    this.target = null;

    this.container.classList.remove('open');
    hideElements(this.closeButton, 1500);

    if (typeof window !== `undefined`) {
        window.scrollTo(0, 0);
    }
    document.querySelector('body').classList.add('no-scroll');

    this.mapOverlay.style.display = "none";
    this.mapOverlay.style.pointerEvents = 'none';
};

Infobox.prototype.open = function (state) {
    if (state && state.type === "abgeordneter") {
        this.map.highlightAbgeordneter(state.id);
    }

    this.current = state;
    this.target = state;

    this.container.classList.add('open');
    showElements(this.closeButton);

    document.querySelector('body').classList.remove('no-scroll');

    if (isMobileOrTerminal() && !isLandscape()) {
        this.mapOverlay.style.display = 'block';
        this.mapOverlay.style.pointerEvents = 'auto';
    }
};

Infobox.prototype.rotate = function (dist) {
    if (Math.abs(dist) > 0) {
        dist /= Math.abs(dist);
    }

    if (this.target !== null) {
        let target = new State(this.target, this.target);
        let rotation = 0;

        if (target.index !== false) {
            target.index = target.index + dist;
            target.id = target.filter[normalize(target.index, target.filter.length)];

            rotation = -target.index * 360 / this.wheelTabs.length;

            this.upateState(target);
        }

        TweenLite.to(this.wheel, 0.5, {
            ease: "power2.out",
            rotation: rotation,
        });
    }
};

Infobox.prototype.back = function () {
    if (this.target !== null) {
        this.upateState(this.target.prev);
    }
};

Infobox.prototype.update = function (state) {

    if (state) {
        this.content.innerHTML = state.data;
        this.container.scrollTop = 0;

        if (isMobileOrTerminal() && document.getElementById('image') && !isLandscape()) {
            document.getElementById('image').style.display = 'none';
        }

        let rotation = 0;

        if (state.index !== false) {
            this.softload(state, [-1, 0, 1]);

            rotation = -state.index * 360 / this.wheelTabs.length;
        } else {
            this.softload(state);
        }

        TweenLite.to(this.wheel, 0.5, {
            ease: "power2.out",
            rotation: rotation,
        });

        this.open(state);
    } else {
        this.close();
    }

    let events = this.events;

    Object.keys(events).forEach(function (key) {
        onClick(key, events[key]);
    });
};

Infobox.prototype.resize = function () {
    this.img_size = [23, 15];

    if (isMobileOrTerminal() && !isLandscape()) {
        if (document.getElementById('image')) {
            document.getElementById('image').style.display = 'none';
        }
        this.wheel.style.display = 'block';
        document.querySelector('.wheel-trigger').style.display = 'block';

        let paddingTop = '0';

        if (typeof window !== `undefined`) {
            if (window.innerWidth > window.innerHeight) {
                this.img_size = [15, 10];

                paddingTop = 15 + 0.35 * window.innerWidth + 'px';
            } else {
                this.img_size = [23, 15];

                paddingTop = (isTerminal() ? 100 : 25) + 0.4 * window.innerWidth + 'px';
            }
        }

        this.container.style.paddingTop = paddingTop;

        this.softclear();

        if (this.current) {
            this.mapOverlay.style.display = 'block';
            this.mapOverlay.style.pointerEvents = 'auto';

            this.softload(this.current, [-1, 0, 1], true);
        }
    } else {

        if (document.getElementById('image')) {
            document.getElementById('image').style.display = 'block';
        }
        this.wheel.style.display = 'none';
        document.querySelector('.wheel-trigger').style.display = 'none';

        this.container.style.paddingTop = 0 + 'px';

        this.mapOverlay.style.display = 'none';
        this.mapOverlay.style.pointerEvents = 'none';
    }

    if (typeof window !== `undefined` && document.getElementById('wheel-content')) {
        document.getElementById('wheel-content').style.width = 5 * window.innerWidth + 'px';
    }
};

Infobox.prototype.upateState = function (state) {
    this.target = state;
    state.data = RenderOrt(infoboxes[state.id]);
    this.update(state);
};

Infobox.prototype.softload = function (state, diff = [0], force = false) {
    let self = this;

    let whitelist = [];

    diff.forEach(function (diff) {
        let id = state.id;
        let tab = self.wheelTabs[0];

        if (state.index !== false) {
            id = state.filter[normalize(state.index + diff, state.filter.length)];
            tab = self.wheelTabs[normalize(state.index + diff, self.wheelTabs.length)];
        }

        whitelist.push(tab);

        if (tab.dataset.type !== state.type || tab.dataset.id !== id || force) {
            let group = createSVGElement('g', {});

            let url = '/images/Karte/orte/' + id + '.jpg';

            if (state.type === "abgeordneter") {
                group.appendChild(createSVGElement('rect', {
                    'x': -self.img_size[0] / 2,
                    'y': -self.img_size[1] / 2,
                    'width': self.img_size[0] + 1,
                    'height': self.img_size[1] + 1,
                    'fill': "#FFFFFF"
                }));

                group.appendChild(createSVGElement('image', {
                    'x': -(self.img_size[0] - 1) / 2,
                    'y': -(self.img_size[1] - 1) / 2,
                    'width': self.img_size[0],
                    'height': self.img_size[1],
                    'href': url,
                    'preserveAspectRatio': "none"
                }));
            } else {
                group.appendChild(createSVGElement('circle', {
                    'cx': 0,
                    'cy': 8,
                    'r': 12,
                    'fill': "#FFFFFF"
                }));

                group.appendChild(createSVGElement('image', {
                    'x': -6,
                    'y': -2,
                    'width': 12,
                    'height': 10,
                    'href': url
                }));
            }

            tab.setAttribute('data-type', state.type);
            tab.setAttribute('data-id', id);

            tab.innerHTML = group.outerHTML;
        }
    });

    self.wheelTabs.forEach(function (tab) {
        tab.style.display = whitelist.includes(tab) ? 'block' : 'none';
    });
};

Infobox.prototype.softclear = function () {
    this.wheelTabs.forEach(function (tab) {
        tab.setAttribute('data-type', null);
        tab.setAttribute('data-id', null);
        tab.innerHTML = '';
    });
};

function State(prev, copy = null) {
    this.prev = prev;

    this.type = copy ? copy.type : null;
    this.id = copy ? copy.id : null;

    this.filter = copy ? copy.filter : [];
    this.index = copy ? copy.index : -1;

    this.data = "";
}

State.prototype.eq = function (state) {
    if (state === null) {
        return false;
    }

    return this.type === state.type && this.id === state.id && this.filter === state.filter && this.index === state.index;
};
export default Infobox;
