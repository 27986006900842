import * as React from "react"

const Infobox = () => {

    return (
        <div>
            <button id="infobox-close" className="close-right hide">
                <span className="icon-close"/> Infobox
            </button>

            <div id="infobox" className="right-sidebar">
                <div id="infobox-wheel"/>
                <div id="infobox-content" className="content"/>
            </div>
        </div>
    )

}

export default Infobox;
