import * as overlay0 from "./overlay-0";
import * as overlay1 from "./overlay-1";
import * as overlay2 from "./overlay-2";
import * as overlay3 from "./overlay-3";
import * as overlay4 from "./overlay-4";
import * as overlay5 from "./overlay-5";
import * as overlay6 from "./overlay-6";
import * as overlay7 from "./overlay-7";
import * as overlay8 from "./overlay-8";
import * as overlay9 from "./overlay-9";
import * as overlay10 from "./overlay-10";
import * as overlay11 from "./overlay-11";
import * as overlay12 from "./overlay-12";

import * as infobox0 from "./infobox-0";
import * as infobox1 from "./infobox-1";
import * as infobox2 from "./infobox-2";
import * as infobox3 from "./infobox-3";
import * as infobox4 from "./infobox-4";
import * as infobox5 from "./infobox-5";
import * as infobox6 from "./infobox-6";
import * as infobox7 from "./infobox-7";
import * as infobox8 from "./infobox-8";
import * as infobox9 from "./infobox-9";
import * as infobox10 from "./infobox-10";
import * as infobox11 from "./infobox-11";
import * as infobox12 from "./infobox-12";

const overlays = {
    "0": overlay0,
    "1": overlay1,
    "2": overlay2,
    "3": overlay3,
    "4": overlay4,
    "5": overlay5,
    "6": overlay6,
    "7": overlay7,
    "8": overlay8,
    "9": overlay9,
    "10": overlay10,
    "11": overlay11,
    "12": overlay12,
};

const infoboxes = {
    "0": infobox0,
    "1": infobox1,
    "2": infobox2,
    "3": infobox3,
    "4": infobox4,
    "5": infobox5,
    "6": infobox6,
    "7": infobox7,
    "8": infobox8,
    "9": infobox9,
    "10": infobox10,
    "11": infobox11,
    "12": infobox12,
};

export {overlays, infoboxes}
