import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Passau/teaser-passau.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1952: Passau </h2>
                    </div>
                </div>
                <p><i>„Wir fordern die Vereinigten Staaten von Europa.“</i><br/><br/></p>
                <p>
                    Gemeinsam riefen amerikanische Offiziere und die Stadt Passau im Jahr 1952 die Europäischen Wochen
                    ins Leben. Ihr Ziel war es, die Idee von einem geeinten Europa zu fördern, die kulturelle
                    Verständigung in Europa zu vertiefen und politische Barrieren abzubauen.
                </p>
                <p>
                    Ein europäischer Zusammenschluss sollte den Nationalismus überwinden und demokratisches Bewusstsein
                    stärken. Im März 1952 kamen Vertreter der amerikanischen Militärverwaltung mit Vertretern der
                    Vereine, der Kirche und der Stadtspitze Passaus zusammen und beschlossen die Festspiele noch für den
                    Sommer 1952. Diese sollten die kulturelle Vielfalt Europas mit Theater, Musik, Filmen und
                    Ausstellungen abbilden, aber auch ein Vortragsprogramm mit Vertretern der europäischen Bewegung
                    bieten. Den Höhepunkt der Veranstaltung bildete der Vortrag des Spitzenrepräsentanten der
                    Europa-Bewegung vor zwei- bis dreitausend Zuhörern: Eugen Kogon sprach vor allem über Initiativen
                    für eine europäische Verfassung.
                </p>
                <p>
                    Seit der Gründung finden die europäischen Wochen jedes Jahr statt, seit 1960 organisiert vom
                    Trägerverein „Europäische Wochen Passau e. V.“. Das Programm mit international renommierten
                    Künstlerinnen und Künstlern bietet der europäischen Verständigung dienende kulturelle
                    Veranstaltungen.
                </p>
            </div>
            <Link to="/orte/passau/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

