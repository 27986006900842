import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>
            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Bamberg/teaser-bamberg.jpg"}/>
                    <figcaption>
                        Quelle: Bildarchiv Bayerischer Landtag
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    1919: Bamberg – Spiegelsaal der Harmonie </p>
                <p className="ort-subheader">
                    <i>„Die Staatsgewalt geht von der Gesamtheit des Volkes aus.“</i>
                </p>
                <Link to="/orte/bamberg/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>
        </div>
    )
}

export default Overlay

