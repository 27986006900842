import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (

        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Muenchen-Prannestr/teaser-prannerstrasse.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1818–1933: München – Prannerstraße 8 </h2>
                    </div>
                </div>
                <p><i>„Für das ganze Königreich besteht eine allgemeine in zwey Kammern abgetheilte
                    Stände-Versammlung.“</i><br/><br/>
                </p>
                <p>Das Gebäude an der Prannerstraße beherbergte von 1819 bis 1933 das bayerische Parlament. Die
                    Verfassung des Königreichs Bayern von 1818 sah die Mitwirkung einer Ständeversammlung an der
                    Gesetzgebung vor. Diese bestand aus der Kammer der Reichsräte mit Vertretern aus Adel und
                    Geistlichkeit und der Kammer der vom Volk gewählten Abgeordneten. Wahlberechtigt war in Abhängigkeit
                    von Steuerzahlungen ein kleinerer Teil der Bevölkerung.
                </p>
                <p>
                    Im Zuge der europaweiten Revolution 1848 wurden weitreichende Verfassungsreformen durchgeführt. Alle
                    volljährigen männlichen Staatsbürger, die Steuern zahlten, konnten jetzt die Mitglieder der Kammer
                    der Abgeordneten wählen. Fortan hieß die „Ständeversammlung“ offiziell „Landtag“. Wahlrechtsreformen
                    1905/06 erweiterten die demokratische Basis des Landtags. Vergeblich beantragten 1917 Abgeordnete
                    die Parlamentarisierung des politischen Systems.
                </p>
                <p>
                    Der entscheidende Wandel erfolgte 1918/19. In der Krise am Ende des Ersten Weltkriegs beschloss die
                    zweite Kammer des Landtags die Demokratisierung Bayerns in Form einer parlamentarischen Monarchie.
                    Noch vor der Umsetzung stürzte am 7. November 1918 die Revolution Regierung, Landtag und Monarchie.
                    Kurt Eisner rief den Freistaat aus. Ziele der Revolutionsregierung waren das Ende des Kriegs und die
                    Demokratisierung. Aus ersten allgemeinen Wahlen ging im Januar 1919 ein neuer Landtag hervor. Nach
                    der Ermordung Eisners wich das Parlament nach Bamberg aus. Dort beschloss es im August 1919 eine
                    demokratische Verfassung.
                </p>
                <p>
                    In den folgenden 15 Jahren tagte der in seinen Kompetenzen wesentlich gestärkte Landtag in der
                    Münchner Prannerstraße. Unter dem Druck des NS-Regimes gab die Mehrheit im Landtag 1933 seine
                    Kompetenzen preis. Mit dem „Gesetz über den Neuaufbau des Reiches“ wurde der Landtag aufgelöst. Im
                    Krieg wurde das Gebäude zerstört. Der im Dezember 1946 erstmals wieder frei gewählte Bayerische
                    Landtag etablierte sich ab 1949 im Maximilianeum in München.
                </p>
            </div>
            <Link to="/orte/muenchen-prannerstrasse/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

