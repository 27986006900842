import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>
            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Memmingen/teaser-memmingen.jpg"}/>
                    <figcaption>
                        Quelle: Stadtarchiv Memmingen
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    1525: Memmingen – Haus der Kramerzunft </p>
                <p className="ort-subheader">
                    <i>„…das wir frey seien und wollen sein.“</i>
                </p>
                <Link to="/orte/memmingen/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>

        </div>
    )
}

export default Overlay

