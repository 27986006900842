import * as Q from 'q';
import * as ol from 'ol';
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import * as format from 'ol/format'
import * as style from 'ol/style';
import * as interaction from 'ol/interaction';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import * as proj from 'ol/proj';

import {containsXY, getCenter, isEmpty} from 'ol/extent';
import {optionsFromCapabilities} from 'ol/source/WMTS';
import {inflate} from 'pako';

import {TextDecoder as IETextDecoder} from 'text-encoding';

import {isMobileOrTerminal} from './Helper'

import JSONFeatures from "../../../static/map/features.json";

import {isTerminal} from "../container/container";

if (typeof window !== `undefined` && !window['TextDecoder']) {
    window['TextDecoder'] = IETextDecoder;
}

/**
 *
 * @param options
 * @constructor
 */
function Map(options) {
    this.options = options;

    if (!isTerminal()) {
        this.webatlasLayer = new layer.Tile({preload: 7, opacity: 1, minZoom: 10});
        this.wahlkreiseLayer = new layer.Vector({name: 'wahlkreise', updateWhileAnimating: true, maxZoom: 10});
    } else {
        this.wahlkreiseLayer = new layer.Vector({name: 'wahlkreise', updateWhileAnimating: true, maxZoom: 100});
    }

    this.orteShadowsOptions = {name: 'shadows', updateWhileAnimating: true, renderBuffer: 500};
    this.orteRhombusOptions = {name: 'rhombus', updateWhileAnimating: true, renderBuffer: 500};

    this.orteShadowsLayers = {};
    this.orteRhombusLayers = {};
    this.abgeordnete = {};
    this.abgeordnetenKeys = null;

    let i = 0;
    this.wahlkreiseLayerPosition = i;
    this.orteShadowsPosition = ++i;
    this.orteRhombusPosition = ++i;

    this.view = new ol.View({
        enableRotation: false,
        center: options.center ? options.center : getCenter(options.extent),
        extent: options.extent,
        zoom: options.zoom,
        projection: options.projection,
        minZoom: options.minZoom,
        maxZoom: options.maxZoom,
        layers: new ol.Collection({})
    });

    this.map = new ol.Map({
        target: options.target,
        view: this.view,
        interactions: interaction.defaults({
            pinchRotate: false,
            altShiftDragRotate: false
        })
    });

    this.overlays = [];
}

Map.prototype.scale = function (resolution) {
    return 5 / Math.pow(resolution, 0.3);
};

Map.prototype.on = function (event, callback) {
    this.map.on(event, callback);
};

Map.prototype.getFeaturesAtPixel = function (pixel) {
    let features = {
        'rhombus': [],
        'shadows': [],
    };

    this.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        if (layer.get('name') in features) {
            features[layer.get('name')].push(feature);
        }
    });

    return features;
};

Map.prototype.highlightAbgeordneter = function (abgeordneter) {
    let maxZoomLevel = Math.max(...Object.keys(this.orteRhombusLayers));
    let feature = this.orteRhombusLayers[maxZoomLevel].getSource().getFeatureById(abgeordneter);
    this.flyTo(feature.getGeometry().getCoordinates());
};

/**
 *
 * @param position
 */
Map.prototype.flyTo = function (position) {
    if (isEmpty(position)) {
        return;
    }

    if (position.length === 4) {
        if (isMobileOrTerminal()) {
            this.view.fit(position, {duration: 1000, padding: [75, 75, 475, 75]});
        } else {
            this.view.fit(position, {duration: 1000, padding: [75, 475, 75, 75]});
        }
    }

    if (position.length === 2) {
        if (isMobileOrTerminal()) {
            position[1] -= 300;
        } else {
            position[0] += 400;
        }

        this.view.cancelAnimations();

        let zoom = this.view.getZoom();
        let view = this.view;

        let minZoom = 10;
        let maxZoom = zoom; //14;

        if (zoom < minZoom + 1) {
            minZoom = zoom;
        }

        if (!containsXY(this.view.calculateExtent(this.map.getSize()), position[0], position[1])) {
            view.animate({
                zoom: minZoom,
                duration: 500
            }, function () {
                view.animate({
                    center: position,
                    duration: 1500
                });

                view.animate({
                    zoom: maxZoom,
                    duration: 2000
                });
            });
        } else {
            view.animate({
                center: position,
                duration: 1000
            });
            view.animate({
                zoom: maxZoom,
                duration: 1000
            });
        }
    }
};

Map.prototype.loadCapabilities = function (url) {
    const deferred = Q.defer();
    const map = this.map;
    const webatlasLayer = this.webatlasLayer;
    const webatlasLayerPosition = this.webatlasLayerPosition;

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function () {
        if (this.status === 200) {
            let result = (new format.WMTSCapabilities()).read(this.response);
            let webatlas = optionsFromCapabilities(result, {
                layer: 'by_webkarte_grau',
                requestEncoding: 'REST',
                matrixSet: 'smerc'
            });
            webatlas.cacheSize = 1000000;
            webatlasLayer.setSource(new source.WMTS(webatlas));
            map.getLayers().insertAt(webatlasLayerPosition, webatlasLayer);
            deferred.resolve();
        }
    };
    xhr.send();

    return deferred.promise;
};

Map.prototype.loadWahlkreise = function (url) {
    let deferred = Q.defer();

    const map = this.map;
    const self = this

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
        if (this.status === 200) {
            self.wahlkreiseLayer.setSource(new source.Vector({
                features: new format.KML({extractStyles: false})
                    .readFeatures(
                        new TextDecoder("utf-8").decode(inflate(this.response)), {
                            featureProjection: 'EPSG:3857'
                        }
                    )
            }));

            self.wahlkreiseLayer.setStyle(new style.Style({
                fill: new style.Fill({color: [233, 233, 233, 1]}),
                stroke: new style.Stroke({color: [255, 255, 255, 1], width: 2})
            }));

            map.getLayers().insertAt(self.wahlkreiseLayerPosition, self.wahlkreiseLayer);

            self.hideOverlay();
        }
    };
    xhr.send();
    deferred.resolve();
    return deferred.promise;
};

Map.prototype.loadAbgeordneteLocal = function (callback = null) {

    const self = this;
    const minZoom = 1;
    const maxZoom = 20;
    const options = {minZoom: minZoom, maxZoom: maxZoom};

    this.orteShadowsLayers[minZoom] = new layer.Vector(Object.assign({}, this.orteShadowsOptions, options));
    this.orteRhombusLayers[minZoom] = new layer.Vector(Object.assign({}, this.orteRhombusOptions, options));

    let rhombusFeatures = [];
    let shadowFeatures = [];

    const res_features = Object.values(JSONFeatures);

    res_features.sort((a, b) => {
        if (a.abgeordneter.sortkey > b.abgeordneter.sortkey) return 1;
        if (a.abgeordneter.sortkey < b.abgeordneter.sortkey) return -1;
        return 0;
    });

    res_features.forEach((res_feature) => {
        const point = new Point(res_feature.koordinaten);
        point.applyTransform(proj.getTransform('EPSG:4326', 'EPSG:3857'));

        let svgs = ['shadow', 'rhombus'].map(function (v) {
            let img;
            let txt;

            let reduce = minZoom < 10 && (!isMobileOrTerminal() || minZoom >= 8);

            if (res_feature.fraktion.id === 'city') {
                img = new style.Circle({
                    radius: reduce ? 5 : 0,
                    fill: new style.Fill({color: '#fff'})
                });
                txt = new style.Text({
                    textAlign: "right",
                    textBaseline: "middle",
                    offsetX: -10,
                    font: '11px sans-serif',
                    text: res_feature.abgeordneter.vorname,
                    fill: new style.Fill({color: [0, 0, 0, 1]}),
                });
            } else {
                img = new style.Icon({
                    opacity: 1,
                    src: '/images/Karte/' + v + '.svg',
                    scale: 1.5
                });
            }

            if (res_feature.abgeordneter.funktion != null) {
                txt = new style.Text({
                    textAlign: "center",
                    textBaseline: "top",
                    offsetY: 2 + ((res_feature.fraktion.id === 'city') ? 5 : 0),
                    font: '12px sans-serif',
                    text: res_feature.abgeordneter.funktion.replace(/\\n/g, '\n'),
                    fill: new style.Fill({color: [0, 0, 0, 1]}),
                });
            }

            let svg = new style.Style({
                image: img,
                text: reduce ? txt : null
            });

            let feature = new Feature({geometry: point});
            feature.setId(res_feature.id);
            feature.setStyle(function (feature, resolution) {
                if (res_feature.fraktion.id !== 'city') {
                    svg.getImage().setScale(self.scale(resolution));

                    if (v === "tweet") {
                        svg.getImage().setOpacity(self.abgeordnete[res_feature.id].tweets.overlay !== null ? 1 : 0);
                    }
                }

                return svg;
            });

            return feature;
        });

        shadowFeatures.push(svgs[0]);
        rhombusFeatures.push(svgs[1]);
        this.abgeordnete[res_feature.id] = res_feature;
    });

    if (callback) {
        callback();
    }
    this.orteShadowsLayers[minZoom].setSource(new source.Vector({features: shadowFeatures}));
    this.map.getLayers().insertAt(this.orteShadowsPosition, this.orteShadowsLayers[minZoom]);

    this.orteRhombusLayers[minZoom].setSource(new source.Vector({features: rhombusFeatures}));
    this.map.getLayers().insertAt(this.orteRhombusPosition, this.orteRhombusLayers[minZoom]);
};

Map.prototype.hideOverlay = function () {
    document.querySelector('.karte .loading-karte').classList.remove('show');
    setTimeout(() => {
        document.querySelector('.karte .loading-karte').classList.add('hide');
    }, 2000);
}

Map.prototype.getAbgeordnetenKeys = function () {

    if (this.abgeordnetenKeys === null) {
        this.abgeordnetenKeys = [];

        Object.keys(this.abgeordnete).forEach((key) => {
            if (this.abgeordnete[key].fraktion.id === '0') {
                this.abgeordnetenKeys.push(key);
            }
        });

        const self = this;
        this.abgeordnetenKeys.sort(function (a, b) {
            if (self.abgeordnete[a].abgeordneter.sortkey > self.abgeordnete[b].abgeordneter.sortkey) return 1;
            if (self.abgeordnete[a].abgeordneter.sortkey < self.abgeordnete[b].abgeordneter.sortkey) return -1;
            return 0;
        });
    }
    return this.abgeordnetenKeys;
};

export default Map;
