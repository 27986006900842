import * as React from 'react'
import {Link} from "gatsby"

const Overlay = () => {
    return (
        <div>
            <div className="pop-up-media">
                <figure>
                    <img alt="" className="ort-img" src={"/images/Vilshofen/teaser-vilshofen.jpg"}/>
                    <figcaption>
                        Quelle: Stadtarchiv Vilshofen, Archivsammlung Ludwig Maier, Foto: P. Fuchs
                    </figcaption>
                </figure>
            </div>
            <div className="pop-up-body">
                <p className='ort-header'>
                    1919: Vilshofen an der Donau </p>
                <p className="ort-subheader">
                    <i>„Innerlich wirkte das Festbier, äußerlich das Diskutieren.“</i>
                </p>
                <Link to="/orte/vilshofen/" className='ort-text icon-arrow-right load-infobox'>
                    Mehr erfahren
                </Link>
            </div>

        </div>
    )
}

export default Overlay

