import * as React from 'react'
import {Link} from "gatsby";

const Overlay = () => {
    return (
        <div className="ort">
            <div className="information">
                <div className="image" id="image">
                    <img src={"/images/Vilshofen/teaser-vilshofen.jpg"} alt=""/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>1919: Vilshofen an der Donau </h2>
                    </div>
                </div>
                <p><i>„Innerlich wirkte das Festbier, äußerlich das Diskutieren.“</i><br/><br/></p>
                <p>Alljährlich steht am Aschermittwoch der rhetorische Schlagabtausch der Parteien im Interesse der
                    Öffentlichkeit. Als „Geburtsort“ des Politischen Aschermittwochs gilt
                    Vilshofen in Niederbayern. Schon im 16. Jahrhundert gab es hier am Aschermittwoch einen Viehmarkt.
                </p>
                <p>
                    1919 lud der Bayerische Bauernbund erstmals am Aschermittwoch zu einer politischen Versammlung ein.
                    In den folgenden Jahren fanden unregelmäßig von verschiedenen Akteuren organisierte Kundgebungen
                    statt, beispielsweise Veranstaltungen vom Christlichen Bauernverein oder dem Bauern- und
                    Mittelstandsbund.
                    Von 1933 bis 1937 nutzte die NSDAP diese Tradition, ließ dann jedoch wieder davon ab. Es blieb der
                    Viehmarkt.
                </p>
                <p>
                    In der Nachkriegszeit gab es ab 1948 wieder politische Kundgebungen, die zunächst vor allem von der
                    heftigen Auseinandersetzung zwischen CSU und Bayernpartei geprägt waren. Ab 1953 zog die CSU mit
                    Franz Josef Strauß als zugkräftigem Redner die Aufmerksamkeit auf sich. Der zum traditionellen
                    Austragungsort gewordene Wolferstetter Keller konnte die Besucherinnen und Besucher bald nicht mehr
                    fassen. 1975 verlegte die CSU ihre Veranstaltung in die Passauer Nibelungenhalle. Im Wolferstetter
                    Keller versammelte sich fortan die SPD und bezog Position gegen die CSU.
                </p>
                <p>
                    Im Laufe der Zeit nutzten auch andere politische Parteien die mediale Aufmerksamkeit für den
                    Politischen Aschermittwoch, der sich von einem lokalen Ereignis zu einem politischen Schlagabtausch
                    und Medienevent mit landes- und bundesweiter Ausstrahlung gewandelt hat.
                </p>
            </div>
            <Link to="/orte/vilshofen/" className='ort-text icon-arrow-right'> Mehr erfahren </Link>

        </div>
    )
}

export default Overlay

